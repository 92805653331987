/** @format */

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body,
code,
html,
* {
  font-family: Poppins !important;
  scroll-behavior: smooth;
}

html {
  font-size: 16px;
}

i {
  font-family: "Font Awesome 6 Free" !important;
}

button,
a {
  cursor: pointer !important ;
}

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

.silver {
  background: linear-gradient(
    113deg,
    #b5b7bb -3.09%,
    rgba(216, 216, 216, 0.3) 100%
  );
}
.gold {
  background: linear-gradient(113deg, #b8860b -3.09%, #ecbd00 100%);
}
.platinum {
  background: linear-gradient(
    113deg,
    #61605e -3.09%,
    #494846 -3.08%,
    #939290 100%
  );
}
.diamond {
  background: linear-gradient(
    113deg,
    #437ff9 -3.09%,
    #4aa5f8 -3.08%,
    #6dcefc 100%
  );
}

.fade-entering {
  opacity: 0;
}

.fade-entered {
  opacity: 1;
  transition: opacity 300ms ease-in-out;
}

.fade-exiting {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}
.flip-horizontal {
  --transform-scale-x: -1;
}

.disabled_button {
  opacity: 0.6;
  cursor: not-allowed !important;
}

.membership_card ul,
.membership_card ol {
  list-style: disc;
}

.Product_Key_Ingredeints ul {
  list-style: disc;
}

.errMessage {
  color: red;
  font-weight: bold;
  font-size: 14px;
  margin-top: 20px;
  text-align: center;
}

.highlight {
  background-color: yellow; /* Adjust the highlighting style as needed */
  font-weight: bold;
}

.edit-cont .ql-toolbar.ql-snow {
  display: none !important;
}

.ant-drawer-body {
  padding: 0 !important;
}

.conten-table {
  padding: 20px;
  font-size: 14px;
  border: 1px solid rgb(229 216 150);
  padding: 10px;
  color: rgb(229 216 150);
}

.conten-table p,
.conten-table span,
.conten-table ul,
.conten-table li {
  background-color: transparent !important;
}

.edit-cont .ql-editor p,
.edit-cont .ql-editor span,
.edit-cont .ql-editor ul,
.edit-cont .ql-editor li {
  color: rgb(229 216 150);
}

.quill-Editable-div {
  width: 500px;
}

.ingredient-textarea {
  width: 100%;
  height: 200px;
  background-color: transparent;
  outline: none;
  border: 1px solid rgb(229 216 150);
  color: rgb(229 216 150);
  padding: 10px;
  font-size: 14px;
  color: rgb(229 216 150);
  border-radius: 10px;
}

/* Brand Container */
.New_Brand_Container {
  display: flex;
  justify-content: center;
  gap: 2.5rem;
  flex-wrap: wrap;
}
.New_Brand_Container .Item {
  width: 43rem;
  height: 28rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  cursor: pointer;
}
.New_Brand_Container h3 {
  color: #e5d896;
  font-weight: 900;
  z-index: 2;
  text-align: center;
  font-size: 2.25rem;
}
.New_Brand_Container .bg {
  background-color: rgb(0 0 0);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0.6;
}
